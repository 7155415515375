import React, { useEffect } from "react";

// STYLE
import "./accessibility.scss";

import { useAccessibility } from "../../../hooks/redux";

// COMPONENTS
import Button from "../../atoms/Button/Button";

const Accessibility = (props) => {

    const [accessibility, { normalFont, biggerFont, biggestFont }] = useAccessibility();

    useEffect(() => {
        const html = document.getElementsByTagName('html')[0];

        html.style.fontSize = `${accessibility.fontSize}%`;
    }, [accessibility.fontSize])

    return (
        <div className="accessibility--bar">
            <label>Rozmiar czcionki</label>
            <div className="accessibility--bar__fonts">
                <Button onClick={normalFont} aria-label="Rozmiar czcionki: normalny">A</Button>
                <Button onClick={biggerFont} aria-label="Rozmiar czcionki: większy">A+</Button>
                <Button onClick={biggestFont} aria-label="Rozmiar czcionki: największy">A++</Button>
            </div>
        </div>
    );
}

export default Accessibility;
