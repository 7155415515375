import { graphql, useStaticQuery } from "gatsby";

export const useCuriosities = () => {
    const {
        allStrapiCuriosities: { nodes },
    } = useStaticQuery(graphql`
        query {
            allStrapiCuriosities {
                nodes {
                    images {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                            publicURL
                        }
                    }
                    description
                    name
                    video
                }
            }
        }
    `);

    return nodes;
};

export default useCuriosities;
