import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import classnames from "classnames";

// COMPONENTS
import { Container, Row, Col } from "reactstrap";

import Button from "../../atoms/Button/Button";
import Link from "../../atoms/Link/Link";
import Icon from "../../atoms/Icon/Icon";
import PlainText from "../../atoms/PlainText/PlainText";

// STYLE
import "./bottomNav.scss";
import { useBottomNav, useSideDrawer, useAuth } from "../../../hooks/redux";

const BottomNav = () => {
    const location = useLocation();
    const [bottomNav, { hideBottomNav, showBottomNav }] = useBottomNav();
    const [sideDrawer] = useSideDrawer();
    const [auth] = useAuth();

    const locationsWithHiddenBottomNav = [
        "/",
        "/app/logowanie",
        "/app/rejestracja",
        "/pomoc",
        "/ustawienia",
    ];

    const locationsWithMyAccountSelected = [
        "/app/moje-osiagniecia",
        "/app/edycja-konta",
    ];

    useEffect(() => {
        if (
            sideDrawer.open ||
            locationsWithHiddenBottomNav.includes(location.pathname)
        ) {
            hideBottomNav();
        } else {
            showBottomNav();

            setTimeout(() => {
                window.dispatchEvent(new Event("resize"));
                window.dispatchEvent(new Event("change"));
                window.dispatchEvent(new Event("blur"));
            }, 1000);
        }
    }, [sideDrawer.open, location]);

    return (
        <nav
            className={classnames("bottom-nav", {
                "bottom-nav--hide": !bottomNav.visible,
                "bottom-nav--narrow": !auth.isLoggedIn,
            })}
        >
            <Container>
                <Row>
                    <Col>
                        <ul className="bottom-nav--links">
                            {auth.isLoggedIn && (
                                <li>
                                <Link
                                    className="bottom-nav--link"
                                    to="/app/moje-trasy"
                                    tabIndex={!bottomNav.visible ? -1 : ''}
                                >
                                    <Button
                                        withoutaction="true"
                                        className="btn--circle btn btn-default"
                                        style={{
                                            marginBottom: "0.8rem",
                                            width: 40,
                                            height: 40,
                                        }}
                                    >
                                        <Icon className="icon-track-min" />
                                    </Button>
                                    <PlainText className="btn--label btn--label__sm text-center">
                                        Moje trasy
                                    </PlainText>
                                </Link>
                                </li>
                            )}

                            <li>
                                <Link
                                    className="bottom-nav--link"
                                    to="/ciekawostki"
                                    tabIndex={!bottomNav.visible ? -1 : ''}
                                >
                                    <Button
                                        withoutaction="true"
                                        className="btn--circle btn btn-default"
                                        style={{
                                            marginBottom: "0.8rem",
                                            width: 40,
                                            height: 40,
                                        }}
                                    >
                                        <Icon className="icon-monocle-min" />
                                    </Button>
                                    <PlainText className="btn--label btn--label__sm text-center">
                                        Ciekawostki
                                    </PlainText>
                                </Link>
                            </li>
                            <li>
                                <Link className="bottom-nav--link" to="/trasy" tabIndex={!bottomNav.visible ? -1 : ''}>
                                    <Button
                                        withoutaction="true"
                                        className="btn--circle btn btn-default"
                                        style={{
                                            marginBottom: "0.8rem",
                                            width: 40,
                                            height: 40,
                                        }}
                                    >
                                        <Icon className="icon-pin-min" />
                                    </Button>
                                    <PlainText className="btn--label btn--label__sm text-center">
                                        Trasy
                                    </PlainText>
                                </Link>
                            </li>
                            <li>
                                <Link className="bottom-nav--link" to="/wydarzenia" tabIndex={!bottomNav.visible ? -1 : ''}>
                                    <Button
                                        withoutaction="true"
                                        className="btn--circle btn btn-default"
                                        style={{
                                            marginBottom: "0.8rem",
                                            width: 40,
                                            height: 40,
                                        }}
                                    >
                                        <Icon className="icon-calendar-min" />
                                    </Button>
                                    <PlainText className="btn--label btn--label__sm text-center">
                                        Wydarzenia
                                    </PlainText>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`bottom-nav--link ${locationsWithMyAccountSelected.includes(
                                        location.pathname
                                    )
                                            ? "link--active"
                                            : ""
                                        }`}
                                    to="/app/moje-konto"
                                    tabIndex={!bottomNav.visible ? -1 : ''}
                                >
                                    <Button
                                        withoutaction="true"
                                        className="btn--circle btn btn-default"
                                        style={{
                                            marginBottom: "0.8rem",
                                            width: 40,
                                            height: 40,
                                        }}
                                    >
                                        <Icon className="icon icon-user-min" />
                                    </Button>
                                    <PlainText className="btn--label btn--label__sm text-center">
                                        {auth.isLoggedIn
                                            ? "Moje konto"
                                            : "Zaloguj się"}
                                    </PlainText>
                                </Link>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </nav>
    );
};

export default BottomNav;
