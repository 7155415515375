import React, { useEffect } from "react";

import "./aboutModal.scss";
import { useSideDrawer, useAboutModal } from "../../../hooks/redux";
import logo1 from "../../../assets/images/ue-logo-1.svg";
import logo2 from "../../../assets/images/ue-logo-2.svg";
import logo3 from "../../../assets/images/ue-logo-3.svg";
import logo4 from "../../../assets/images/ue-logo-4.svg";
import ueLogo from "../../../assets/images/ue-loga.svg";
import Modal from "../Modal/Modal";
import Heading from "../../atoms/Heading/Heading";
import * as Strapi from "../../../hooks/strapi";

const AboutModal = () => {
    const about = Strapi.useAbout();
    const global = Strapi.useGlobal();
    const [sideDrawer, { hideSideDrawer }] = useSideDrawer();
    const [aboutModal, { hideAboutModal }] = useAboutModal();

    useEffect(() => {
        if (aboutModal.visible) {
            hideSideDrawer();
        }
    }, [aboutModal.visible]);

    return (
        <Modal modal={aboutModal.visible} setModal={() => hideAboutModal()}>
            <Heading as="h2">{about.name}</Heading>
            <div>
                <img
                    className="img-fluid"
                    src={global.logo.localFile.publicURL}
                    alt="Rynarzewo+Zamość- ekran startowy"
                />
            </div>
            <div className="ue-logos">
                <img src={ueLogo} alt="Loga UE" />
            </div>

            <div
                dangerouslySetInnerHTML={{ __html: about.description }}
                className="about-modal-content"
            ></div>
        </Modal>
    );
};

export default AboutModal;
