import React from "react";
import classnames from "classnames";

// STYLE
import "./heading.scss";

const Heading = (props) => {
    const allowed = ["h1", "h2", "h3"];
    const as = props.as && allowed.includes(props.as) ? props.as : "h1";

    return as === "h3" ? (
        <h3 className={classnames("h3", props.className)}>{props.children}</h3>
    ) : as === "h2" ? (
        <h2 className={classnames("h2", props.className)}>{props.children}</h2>
    ) : (
        <h1 className={classnames("h1", props.className)}>{props.children}</h1>
    );
};

export default Heading;
